<script setup lang="ts">
import type { Entry } from "contentful";
import {
  isTypeDownload,
  type TypeArticleSkeleton,
  type TypeDownloadSkeleton,
} from "~/types/contentful";
import type { TypeVimeo } from "~/types/TypeVimeo";

const props = defineProps<{
  download: Entry<TypeDownloadSkeleton, "WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
  featuredArticle?: Entry<
    TypeArticleSkeleton,
    "WITHOUT_UNRESOLVABLE_LINKS",
    "en-GB"
  >;
  title?: string;
  subtitle?: string;
}>();

const videoOpened = ref(false);

const { data: vimeo, execute: executeVimeoFetch } = await useLazyFetch<TypeVimeo>(
  "https://vimeo.com/api/oembed.json",
  {
    query: {
      url: computed(() => props.download.fields.url),
    },
    immediate: false,
  },
);

watch(props.download, () => {
  if (isTypeDownload(props.download)) {
    executeVimeoFetch();
  }
}, { immediate: true });
</script>

<template>
  <Transition
    enter-active-class="duration-300 transition ease-out origin-top"
    enter-from-class="scale-y-0"
    enter-to-class="scale-y-100"
  >
    <UiVimeoPlayer
      v-if="videoOpened"
      class="aspect-video max-h-[calc(100lvh-143px)] w-screen bg-black"
      player-class="max-h-[calc(100lvh-143px)] w-screen"
      :video-url="download.fields.url"
      :autoplay="true"
    />
  </Transition>

  <UiContainer
    v-if="!videoOpened"
    class="relative bg-dark-blue-grey-two py-4 text-white"
  >
    <template #outside>
      <NuxtImg
        v-if="download.fields.videoThumbnail?.fields.file?.url"
        class="absolute top-0 size-full object-cover lg:object-center"
        :src="download.fields.videoThumbnail?.fields.file?.url"
        :alt="download.fields.videoThumbnail?.fields.title"
        sizes="sm:100vw md:100vw lg:100vw xl:100vw xxl:100vw"
        :height="600"
        fit="contain"
        loading="lazy"
      />
    </template>

    <HeaderBlockBreadcrumbs class="relative z-10 text-white" />

    <header class="relative z-10 grid grid-cols-12">
      <div
        class="col-span-9 flex flex-col justify-between rounded-b p-4 text-white md:px-6 md:pt-9"
      >
        <UiVideoInfo
          :download="download"
          :duration="vimeo?.duration"
          :show-date="true"
          class="mb-3"
        />

        <div>
          <h1>{{ title || download.fields.title }}</h1>
          <p
            v-if="vimeo?.title ?? subtitle"
            class="font-normal"
          >
            {{ subtitle || vimeo?.title }}
          </p>
        </div>
      </div>
      <div class="col-span-3">
        <UiDownloadLink
          :download="download"
          :disable-modal="true"
          class-list="h-full flex items-center justify-center"
          @video-download="() => (videoOpened = !videoOpened)"
        >
          <div
            class="pointer-events-none flex h-[44px] w-7 items-center justify-center rounded bg-light-royal-blue text-white"
          >
            <NuxtIcon
              v-if="!videoOpened"
              name="fairr:play"
              size="24"
            />
            <NuxtIcon
              v-else
              name="fairr:cross"
              size="24"
            />
          </div>
        </UiDownloadLink>
      </div>
    </header>
  </UiContainer>
</template>
